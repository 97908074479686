<template>
  <div>
    <a-header />
    <z-stepper />
    <div class="content">

      <div class="pt-5">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    components: {
      'a-header': () => import('@/plugins/app/_layout/a-header.vue'),
      'z-stepper': () => import('@/plugins/app@components/stepper/z-stepper.vue')
    }
  }
</script>

<style lang="sass" scoped>
  .content
    padding-top: 124px
    margin-bottom: 60px
    padding-bottom: 60px
</style>
